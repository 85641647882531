/* Links
------------------------------ */

// variables block
:root {
  --link-font-size: var(--base-font-size);
  --link-line-height: var(--base-line-height);
  --link-font-weight: 600;
}

.link {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: var(--link-font-size);
  line-height: var(--link-line-height);
  font-weight: var(--link-font-weight);
  color: var(--color-accent-600);
  transition: var(--transition-material-standard);
  &:hover,
  &:active {
    color: var(--color-accent-700);
  }
  &--scroll-action {
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    width: 48px;
    height: auto;
    > svg {
      fill: var(--color-grey-800);
      opacity: 0.5;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}
