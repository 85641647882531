// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1200px) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: $screen-width-xs,
    sm: $screen-width-sm,
    md: $screen-width-md,
    lg: $screen-width-lg,
    xl: $screen-width-xl
  )
  !default;
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);
