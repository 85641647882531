body {
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  color: var(--color-grey-600);
  letter-spacing: var(--base-letter-spacing);
  line-height: var(--base-line-height);
  background: var(--color-white);
}

img {
  max-width: 100%;
  height: auto;
}

a {
  font: inherit;
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: transparent;
  }
  .icon-wrapper {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-500);
        transition: var(--transition-material-standard);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-500);
        transition: var(--transition-material-standard);
      }
    }
    &:hover > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-800);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-800);
      }
    }
  }
}

a[href^='tel:'],
a[href^='callto:'] {
  color: inherit;
  text-decoration: none;
}

@include headings('.small' p) {
  a {
    transition: var(--transition-material-standard);
    color: var(--color-accent-600);
    &:hover,
    &:active {
      color: var(--color-accent-700);
    }
  }
}

a {
  @include headings {
    transition: var(--transition-material-standard);
    color: var(--color-accent-600);
  }

  &:hover,
  &:active {
    @include headings {
      color: var(--color-accent-700);
    }
  }
}

.page-container {
  min-height: 100vh;
  overflow: hidden;
}
