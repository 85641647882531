/* Feature item
------------------------------ */

// variables block
:root {
  --feature-item-vertical-space-between-items: 10px;

  --feature-item-md-title-font-size: var(--heading-4-size);
  --feature-item-md-title-line-height: var(--heading-4-line-height);

  --feature-item-md-description-font-size: var(--base-font-size);
  --feature-item-md-description-line-height: var(--base-line-height);

  --feature-item-plates-padding-vertical: 24px;
  --feature-item-plates-padding-horizontal: 24px;
  --feature-item-plates-title-font-size: var(--heading-6-size);
  --feature-item-plates-title-line-height: var(--heading-6-line-height);
  --feature-item-plates-title-font-weight: 400;
  --feature-item-plates-border-radius: 4px;
}

.feature-item {
  > * + * {
    margin-top: var(--feature-item-vertical-space-between-items);
  }

  &--md {
    .feature-item {
      &__title {
        font-size: var(--feature-item-md-title-font-size);
        line-height: var(--feature-item-md-title-line-height);
      }
      &__description {
        font-size: var(--feature-item-md-description-font-size);
        line-height: var(--feature-item-md-description-line-height);
      }
    }
  }

  &--default {
    color: var(--color-grey-600);
    .feature-item {
      &__title {
        color: var(--color-grey-800);
      }
    }
  }

  &--plates {
    border: 1px solid var(--color-grey-300);
    padding: var(--feature-item-plates-padding-vertical)
      var(--feature-item-plates-padding-horizontal);
    border-radius: var(--feature-item-plates-border-radius);
    background: var(--color-grey-100);
    .feature-item {
      &__title {
        font-size: var(--feature-item-plates-title-font-size);
        line-height: var(--feature-item-plates-title-line-height);
        font-weight: var(--feature-item-plates-title-font-weight);
      }
    }
  }
}

@media (min-width: $screen-width-lg) {
  :root {
    --feature-item-vertical-space-between-items: 16px;
  }
}
