/* Sections padding variation
------------------------------------ */

// variables block
:root {
  --section-xs-padding: 12px;
  --section-sm-padding: 30px;
  --section-md-padding: 40px;
  --section-lg-padding: 50px;

  // special case for footers
  --section-footer-sm-padding: 24px;
}

.section {
  &--cover {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--xs-padding {
    padding-top: var(--section-xs-padding);
    padding-bottom: var(--section-xs-padding);
  }
  &--sm-padding {
    padding-top: var(--section-sm-padding);
    padding-bottom: var(--section-sm-padding);
    &.block-footer-2-grey,
    &.block-footer-2-white,
    &.block-footer-2-dark {
      padding-top: var(--section-footer-sm-padding);
      padding-bottom: var(--section-footer-sm-padding);
    }
  }
  &--md-padding {
    padding-top: var(--section-md-padding);
    padding-bottom: var(--section-md-padding);
  }
  &--lg-padding {
    padding-top: var(--section-lg-padding);
    padding-bottom: var(--section-lg-padding);
  }

  &--overlay-black {
    position: relative;
    z-index: 1;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      content: '';
      background: rgba(0, 0, 0, 0.55);
      z-index: -1;
    }
  }
}

@media (min-width: $screen-width-sm) {
  :root {
    --section-sm-padding: 35px;
    --section-md-padding: 50px;
    --section-lg-padding: 80px;

    // special case for footers
    --section-footer-sm-padding: 24px;
  }
}

@media (min-width: $screen-width-lg) {
  :root {
    --section-sm-padding: 44px;
    --section-md-padding: 64px;
    --section-lg-padding: 104px;

    // special case for footers
    --section-footer-sm-padding: 44px;
  }
}

/* Sections background combinations
------------------------------------ */

.background-white {
  &.section {
    &--xs-padding + &--xs-padding,
    &--sm-padding + &--sm-padding,
    &--md-padding + &--md-padding,
    &--lg-padding + &--lg-padding,
    &--lg-padding + &--xs-padding,
    &--lg-padding + &--sm-padding,
    &--lg-padding + &--md-padding,
    &--md-padding + &--sm-padding,
    &--md-padding + &--xs-padding,
    &--sm-padding + &--xs-padding {
      padding-top: 0;
    }

    &--md-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-md-padding));
    }

    &--sm-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--lg-padding,
    &--sm-padding.block-footer-2-dark + &--lg-padding,
    &--sm-padding.block-footer-2-white + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-footer-sm-padding));
    }

    &--sm-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--md-padding,
    &--sm-padding.block-footer-2-dark + &--md-padding,
    &--sm-padding.block-footer-2-white + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-footer-sm-padding));
    }

    &--xs-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding {
      padding-top: calc(var(--section-sm-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding.block-footer-2-grey,
    &--xs-padding + &--sm-padding.block-footer-2-white,
    &--xs-padding + &--sm-padding.block-footer-2-dark {
      padding-top: calc(var(--section-footer-sm-padding) - var(--section-xs-padding));
    }
  }
}

.background-accent-600 {
  &.section {
    &--xs-padding + &--xs-padding,
    &--sm-padding + &--sm-padding,
    &--md-padding + &--md-padding,
    &--lg-padding + &--lg-padding,
    &--lg-padding + &--xs-padding,
    &--lg-padding + &--sm-padding,
    &--lg-padding + &--md-padding,
    &--md-padding + &--sm-padding,
    &--md-padding + &--xs-padding,
    &--sm-padding + &--xs-padding {
      padding-top: 0;
    }

    &--md-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-md-padding));
    }

    &--sm-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--lg-padding,
    &--sm-padding.block-footer-2-dark + &--lg-padding,
    &--sm-padding.block-footer-2-white + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-footer-sm-padding));
    }

    &--sm-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--md-padding,
    &--sm-padding.block-footer-2-dark + &--md-padding,
    &--sm-padding.block-footer-2-white + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-footer-sm-padding));
    }

    &--xs-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding {
      padding-top: calc(var(--section-sm-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding.block-footer-2-grey,
    &--xs-padding + &--sm-padding.block-footer-2-white,
    &--xs-padding + &--sm-padding.block-footer-2-dark {
      padding-top: calc(var(--section-footer-sm-padding) - var(--section-xs-padding));
    }
  }
}

.background-grey-800 {
  &.section {
    &--xs-padding + &--xs-padding,
    &--sm-padding + &--sm-padding,
    &--md-padding + &--md-padding,
    &--lg-padding + &--lg-padding,
    &--lg-padding + &--xs-padding,
    &--lg-padding + &--sm-padding,
    &--lg-padding + &--md-padding,
    &--md-padding + &--sm-padding,
    &--md-padding + &--xs-padding,
    &--sm-padding + &--xs-padding {
      padding-top: 0;
    }

    &--md-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-md-padding));
    }

    &--sm-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--lg-padding,
    &--sm-padding.block-footer-2-dark + &--lg-padding,
    &--sm-padding.block-footer-2-white + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-footer-sm-padding));
    }

    &--sm-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--md-padding,
    &--sm-padding.block-footer-2-dark + &--md-padding,
    &--sm-padding.block-footer-2-white + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-footer-sm-padding));
    }

    &--xs-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding {
      padding-top: calc(var(--section-sm-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding.block-footer-2-grey,
    &--xs-padding + &--sm-padding.block-footer-2-white,
    &--xs-padding + &--sm-padding.block-footer-2-dark {
      padding-top: calc(var(--section-footer-sm-padding) - var(--section-xs-padding));
    }
  }
}

.background-grey-100 {
  &.section {
    &--xs-padding + &--xs-padding,
    &--sm-padding + &--sm-padding,
    &--md-padding + &--md-padding,
    &--lg-padding + &--lg-padding,
    &--lg-padding + &--xs-padding,
    &--lg-padding + &--sm-padding,
    &--lg-padding + &--md-padding,
    &--md-padding + &--sm-padding,
    &--md-padding + &--xs-padding,
    &--sm-padding + &--xs-padding {
      padding-top: 0;
    }

    &--md-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-md-padding));
    }

    &--sm-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--lg-padding,
    &--sm-padding.block-footer-2-dark + &--lg-padding,
    &--sm-padding.block-footer-2-white + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-footer-sm-padding));
    }

    &--sm-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-sm-padding));
    }

    &--sm-padding.block-footer-2-grey + &--md-padding,
    &--sm-padding.block-footer-2-dark + &--md-padding,
    &--sm-padding.block-footer-2-white + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-footer-sm-padding));
    }

    &--xs-padding + &--lg-padding {
      padding-top: calc(var(--section-lg-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--md-padding {
      padding-top: calc(var(--section-md-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding {
      padding-top: calc(var(--section-sm-padding) - var(--section-xs-padding));
    }

    &--xs-padding + &--sm-padding.block-footer-2-grey,
    &--xs-padding + &--sm-padding.block-footer-2-white,
    &--xs-padding + &--sm-padding.block-footer-2-dark {
      padding-top: calc(var(--section-footer-sm-padding) - var(--section-xs-padding));
    }
  }
}
