/* WS nav menus
------------------------------ */

// variables block
:root {
  --ws-nav-menu-items-size: 16px;
  --ws-nav-menu-items-line-height: 24px;
  --ws-nav-menu-items-line-font-weight: 600;
  --ws-nav-menu-item-padding-horizontal: 11px;
  --ws-nav-menu-item-padding-vertical: 11px;
  --ws-nav-submenu-offset-left: 24px;

  --ws-nav-menu-md-layout-item-padding-horizontal: 25px;
  --ws-nav-menu-md-layout-item-padding-vertical: 8px;
  --ws-nav-menu-md-layout-indent-between-first-level-items: 40px;

  --ws-nav-white-menu-items-divider-color: var(--color-grey-300);
  --ws-nav-transparent-menu-items-divider-color: var(--color-grey-600);
}

// same for all menus
.ws-nav__menu-list,
.ws-nav__submenu-list {
  text-align: left;
  padding: 0;
  &-item {
    cursor: pointer;
    position: relative;
    border-top: 1px solid var(--ws-nav-white-menu-items-divider-color);
    font-size: var(--ws-nav-menu-items-size);
    line-height: var(--ws-nav-menu-items-line-height);
    font-weight: var(--ws-nav-menu-items-line-font-weight);
    color: var(--ws-nav-white-content-color);
    a {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding: var(--ws-nav-menu-item-padding-horizontal) var(--ws-nav-menu-item-padding-vertical);
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    &.active {
      a {
        color: var(--ws-nav-white-hover-color);
      }
    }
  }
}

// 1st level menu
.ws-nav__menu-list {
  &-item {
    &:last-child {
      border-bottom: 1px solid var(--ws-nav-white-menu-items-divider-color);
    }
  }
}

// 2nd and 3rd level menu
.ws-nav__submenu-list {
  margin-left: var(--ws-nav-submenu-offset-left);
}

// hover and active states
.ws-nav__menu-list,
.ws-nav__submenu-list {
  &-item:hover {
    > .ws-nav__toggle > a,
    > a {
      transition: var(--transition-material-standard);
      color: var(--ws-nav-white-hover-color);
    }
    > .ws-nav__toggle:after {
      border-color: transparent transparent var(--ws-nav-white-hover-color) transparent;
    }
  }
}

// styles for md layout
.ws-nav--layout-md {
  // same for all menus
  .ws-nav__menu-list,
  .ws-nav__submenu-list {
    &-item {
      a {
        padding: 0;
      }
    }
  }

  // 1st menu
  .ws-nav__menu-list {
    margin-left: 20px;
    padding: 0;
    &-item {
      border-top: 0;
      display: inline-block;
      padding: 0;
      &:last-child {
        border-bottom: 0;
      }
      & + .ws-nav__menu-list-item {
        margin-left: 25px;
      }
    }
  }

  // 2nd menu
  .ws-nav__submenu-list {
    margin-left: 0;
    padding: 8px 0;
    &-item {
      border-top: 0;
      padding: var(--ws-nav-menu-md-layout-item-padding-vertical)
        var(--ws-nav-menu-md-layout-item-padding-horizontal);
    }
  }
  @media (min-width: $screen-width-lg) {
    .ws-nav__menu-list {
      &-item {
        & + .ws-nav__menu-list-item {
          margin-left: var(--ws-nav-menu-md-layout-indent-between-first-level-items);
        }
      }
    }
  }
}

.ws-nav--transparent {
  .ws-nav__menu-list,
  .ws-nav__submenu-list {
    &-item {
      color: var(--ws-nav-transparent-content-color);
      border-top: 1px solid var(--ws-nav-transparent-menu-items-divider-color);
      &.active {
        a {
          color: var(--ws-nav-transparent-hover-color);
        }
      }
    }
  }

  // 1st level menu
  .ws-nav__menu-list {
    &-item {
      &:last-child {
        border-bottom: 1px solid var(--ws-nav-transparent-menu-items-divider-color);
      }
    }
  }

  // hover and active states
  .ws-nav__menu-list,
  .ws-nav__submenu-list {
    &-item:hover {
      > .ws-nav__toggle > a,
      > a {
        color: var(--ws-nav-transparent-hover-color);
      }
      > .ws-nav__toggle:after {
        border-color: transparent transparent var(--ws-nav-transparent-hover-color) transparent;
      }
    }
  }

  &.ws-nav--layout-md {
    .ws-nav__menu-list {
      &-item {
        border-top: 0;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .ws-nav__submenu-list {
      &-item {
        border-top: 0;
      }
    }
  }
}
