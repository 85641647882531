/* Typography
-------------------------------- */

// variables block
:root {
  --heading-1-size: 32px;
  --heading-1-line-height: 1.25em;
  --heading-2-size: 24px;
  --heading-2-line-height: 1.26em;
  --heading-3-size: 18px;
  --heading-3-line-height: 1.45em;
  --heading-4-size: 18px;
  --heading-4-line-height: 1.45em;
  --heading-5-size: 18px;
  --heading-5-line-height: 1.45em;
  --heading-6-size: 18px;
  --heading-6-line-height: 1.45em;
}

@include headings {
  font-weight: 300;
  color: var(--color-grey-800);
}

h1,
.heading-1 {
  font-size: var(--heading-1-size);
  line-height: var(--heading-1-line-height);
}

h2,
.heading-2 {
  font-size: var(--heading-2-size);
  line-height: var(--heading-2-line-height);
}

h3,
.heading-3 {
  font-size: var(--heading-3-size);
  line-height: var(--heading-3-line-height);
}

h4,
.heading-4 {
  font-size: var(--heading-4-size);
  line-height: var(--heading-4-line-height);
  font-weight: 400;
}

h5,
.heading-5 {
  font-size: var(--heading-5-size);
  line-height: var(--heading-5-line-height);
}

h6,
.heading-6 {
  font-size: var(--heading-6-size);
  line-height: var(--heading-6-line-height);
  font-weight: 600;
}

.small {
  color: var(--color-grey-500);
}

.big {
  font-size: 16px;
  line-height: 1.625em;
}

@media (min-width: $screen-width-sm) {
  :root {
    --heading-1-size: 56px;
    --heading-1-line-height: 1.2em;
    --heading-2-size: 32px;
    --heading-2-line-height: 1.26em;
    --heading-3-size: 26px;
    --heading-3-line-height: 1.32em;
    --heading-4-size: 22px;
    --heading-4-line-height: 1.3em;
    --heading-5-size: 20px;
  }
}

@media (min-width: $screen-width-lg) {
  :root {
    --heading-1-size: 72px;
    --heading-1-line-height: 1.166em;
    --heading-2-size: 40px;
    --heading-3-size: 32px;
  }
}
