/* Template variables
------------------------------------ */

// media breakpoints
$screen-width-xs: 0;
$screen-width-sm: 576px;
$screen-width-md: 768px;
$screen-width-lg: 992px;
$screen-width-xl: 1200px;

:root {
  // typography
  --template-font-family: 'Source Sans Pro';
  // Native font stack set as the fallback
  --base-font-family: var(--template-font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
  --base-font-size: 16px;
  --base-line-height: 1.714em;
  --base-letter-spacing: 0;
  --base-font-weight: 400;

  --base-border-radius: 4px;

  // colors
  --color-white: #fff;
  --color-grey-100: #fafafa;
  --color-grey-200: #f0f2f5;
  --color-grey-300: #dadde0;
  --color-grey-400: #b0b8bf;
  --color-grey-500: #8e959e;
  --color-grey-600: #646c75;
  --color-grey-700: #30373d;
  --color-grey-800: #141b21;
  --color-black: #000;
  --color-accent-400: #7bb0ed;
  --color-accent-500: #5397e4;
  --color-accent-600: #2777d2;
  --color-accent-700: #1a5daa;
  --color-danger: #dc3545;
  --color-success: #28a745;

  // transitions & animations
  --transition-material-standard: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-material-decelerate: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  --transition-material-accelerate: all 0.2s cubic-bezier(0.4, 0, 1, 1);
}
