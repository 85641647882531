/* Buttons
------------------------------ */

// variables block
:root {
  --button-font-family: var(--base-font-family);
  --button-border-width: 2px;
  --button-border-radius: 4px;

  --button-sm-padding-vertical: 8px;
  --button-sm-padding-horizontal: 40px;
  --button-sm-font-size: 16px;
  --button-sm-line-height: 24px;
  --button-sm-font-weight: 600;

  --button-md-padding-vertical: 10px;
  --button-md-padding-horizontal: 39px;
  --button-md-font-size: 18px;
  --button-md-line-height: 24px;
  --button-md-font-weight: 600;

  --button-lg-padding-vertical: 14px;
  --button-lg-padding-horizontal: 46px;
}

.button {
  display: inline-block;
  font-family: var(--button-font-family);
  max-width: 100%;
  border-radius: var(--button-border-radius);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: var(--transition-material-standard);
  border: var(--button-border-width) solid;
  &:focus,
  :active,
  :active:focus {
    outline: none;
  }
  &--default {
    color: var(--color-white);
    background-color: var(--color-accent-500);
    border-color: var(--color-accent-500);

    &:hover {
      background-color: var(--color-accent-600);
      border-color: var(--color-accent-600);
    }
    &:active {
      background-color: var(--color-accent-700);
      border-color: var(--color-accent-700);
    }
  }
  &--sm {
    padding: var(--button-sm-padding-vertical) var(--button-sm-padding-horizontal);
    font-size: var(--button-sm-font-size);
    font-weight: var(--button-sm-font-weight);
    line-height: var(--button-sm-line-height);
  }
  &--md {
    padding: var(--button-md-padding-vertical) var(--button-md-padding-horizontal);
    font-size: var(--button-md-font-size);
    font-weight: var(--button-md-font-weight);
    line-height: var(--button-md-line-height);
  }
  &--lg {
    font-size: var(--button-md-font-size);
    font-weight: var(--button-md-font-weight);
    line-height: var(--button-md-line-height);
    padding: var(--button-lg-padding-vertical) var(--button-lg-padding-horizontal);
  }
  &--block {
    width: 100%;
  }
}
