/* Groups
------------------------------------ */

// variables block
:root {
  --group-md-margin-between-childs: 8px;
  --group-md-margin-between-childs-invert: -8px;

  --group-lg-margin-between-childs: 16px;
  --group-lg-margin-between-childs-invert: -16px;

  --group-xl-margin-between-childs: 24px;
  --group-xl-margin-between-childs-invert: -24px;

  --group-partners-4-column-margin-between-childs: 40px;
  --group-partners-4-column-margin-between-childs-invert: -40px;

  --group-partners-5-column-margin-between-childs: 40px;
  --group-partners-5-column-margin-between-childs-invert: -40px;

  --group-partners-6-column-margin-between-childs: 40px;
  --group-partners-6-column-margin-between-childs-invert: -40px;
}

.group {
  transform: translateY(var(--group-md-margin-between-childs-invert));
  margin-bottom: var(--group-md-margin-between-childs-invert);
  margin-left: var(--group-md-margin-between-childs-invert);
  & > * {
    display: inline-block;
    margin-top: var(--group-md-margin-between-childs);
    margin-left: var(--group-md-margin-between-childs);
  }
  &--lg {
    transform: translateY(var(--group-lg-margin-between-childs-invert));
    margin-bottom: var(--group-lg-margin-between-childs-invert);
    margin-left: var(--group-lg-margin-between-childs-invert);
    & > * {
      display: inline-block;
      margin-top: var(--group-lg-margin-between-childs);
      margin-left: var(--group-lg-margin-between-childs);
    }
  }
  &--xl {
    transform: translateY(var(--group-xl-margin-between-childs-invert));
    margin-bottom: var(--group-xl-margin-between-childs-invert);
    margin-left: var(--group-xl-margin-between-childs-invert);
    & > * {
      display: inline-block;
      margin-top: var(--group-xl-margin-between-childs);
      margin-left: var(--group-xl-margin-between-childs);
    }
  }
  &--partners-4-column {
    transform: translateY(var(--group-partners-4-column-margin-between-childs-invert));
    margin-bottom: var(--group-partners-4-column-margin-between-childs-invert);
    margin-left: var(--group-partners-4-column-margin-between-childs-invert);
    & > * {
      display: inline-block;
      margin-top: var(--group-partners-4-column-margin-between-childs);
      margin-left: var(--group-partners-4-column-margin-between-childs);
    }
  }
  &--partners-5-column {
    transform: translateY(var(--group-partners-5-column-margin-between-childs-invert));
    margin-bottom: var(--group-partners-5-column-margin-between-childs-invert);
    margin-left: var(--group-partners-5-column-margin-between-childs-invert);
    & > * {
      display: inline-block;
      margin-top: var(--group-partners-5-column-margin-between-childs);
      margin-left: var(--group-partners-5-column-margin-between-childs);
    }
  }
  &--partners-6-column {
    transform: translateY(var(--group-partners-6-column-margin-between-childs-invert));
    margin-bottom: var(--group-partners-6-column-margin-between-childs-invert);
    margin-left: var(--group-partners-6-column-margin-between-childs-invert);
    & > * {
      display: inline-block;
      margin-top: var(--group-partners-6-column-margin-between-childs);
      margin-left: var(--group-partners-6-column-margin-between-childs);
    }
  }
  &--centered {
    & > * {
      display: block;
    }
  }
}

@media (min-width: $screen-width-sm) {
  .group--centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: $screen-width-md) {
  :root {
    --group-partners-6-column-margin-between-childs: 50px;
    --group-partners-6-column-margin-between-childs-invert: -50px;

    --group-partners-5-column-margin-between-childs: 60px;
    --group-partners-5-column-margin-between-childs-invert: -60px;

    --group-partners-4-column-margin-between-childs: 70px;
    --group-partners-4-column-margin-between-childs-invert: -70px;
  }
}
@media (min-width: $screen-width-xl) {
  :root {
    --group-partners-6-column-margin-between-childs: 70px;
    --group-partners-6-column-margin-between-childs-invert: -70px;

    --group-partners-5-column-margin-between-childs: 80px;
    --group-partners-5-column-margin-between-childs-invert: -80px;

    --group-partners-4-column-margin-between-childs: 100px;
    --group-partners-4-column-margin-between-childs-invert: -100px;
  }
}
