/* Headings
/* Adds heading selectors
------------------------------------ */

@mixin headings($selectors: '') {
  @each $selector in $selectors {
    h1,
    .heading-1,
    h2,
    .heading-2,
    h3,
    .heading-3,
    h4,
    .heading-4,
    h5,
    .heading-5,
    h6,
    .heading-6,
    #{$selector} {
      @content;
    }
  }
}
