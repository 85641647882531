.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  text-decoration: underline;
}

.text-regular {
  font-weight: 400;
}

.text-white {
  color: var(--color-white);
}

.text-grey-100 {
  color: var(--color-grey-100);
}

.text-grey-200 {
  color: var(--color-grey-200);
}

.text-grey-300 {
  color: var(--color-grey-300);
}

.text-grey-400 {
  color: var(--color-grey-400);
}

.text-grey-500 {
  color: var(--color-grey-500);
}

.text-grey-600 {
  color: var(--color-grey-600);
}

.text-grey-700 {
  color: var(--color-grey-700);
}

.text-grey-800 {
  color: var(--color-grey-800);
}

.text-black {
  color: var(--color-black);
}

.text-accent-600 {
  color: var(--color-accent-600);
}

.img--rounded {
  border-radius: var(--base-border-radius);
}

.img--circle {
  border-radius: 50%;
}

.img--responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.block-centered {
  margin-left: auto;
  margin-right: auto;
}

.w-100 {
  width: 100%;
}
