/* Responsive Embed
------------------------------ */

// variables block
:root {
}

.responsive-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
