:root {
  --component-ws-branding-padding: 10px;
  --component-ws-branding-text-size: 13px;
  --component-ws-branding-text-margin-right: 5px;
}

.ws-branding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--component-ws-branding-padding);
  font-family: var(--base-font-family);

  span {
    margin-right: var(--component-ws-branding-text-margin-right);
    font-size: var(--component-ws-branding-text-size);
  }

  &--dark {
    background-color: var(--color-grey-800);
    color: var(--color-white);
  }

  &--primary {
    background-color: var(--color-accent-600);
    color: var(--color-white);
  }
}
