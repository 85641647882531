/* Rows vertical indent system
------------------------------------ */

/**
* Important to know from were we have some offset,
* all comments with beginning from... will be delete before release
*/

// variables block
:root {
  --row-xs-new-line-spacing: 10px;
  --row-xs-new-line-spacing-invert: -10px;
  --row-sm-new-line-spacing: 20px;
  --row-sm-new-line-spacing-invert: -20px;
  --row-md-new-line-spacing: 30px;
  --row-md-new-line-spacing-invert: -30px;
  --row-lg-new-line-spacing: 40px;
  --row-lg-new-line-spacing-invert: -40px;
  --row-xl-new-line-spacing: 50px;
  --row-xl-new-line-spacing-invert: -50px;
}

// from footer, block-footer-2(-white, -dark, -grey)
.row--xs-new-line-spacing {
  transform: translateY(var(--row-xs-new-line-spacing-invert));
  margin-bottom: var(--row-xs-new-line-spacing-invert);
}

.row--xs-new-line-spacing > [class*='col'] {
  margin-top: var(--row-xs-new-line-spacing);
}

// from product
.row--sm-new-line-spacing {
  transform: translateY(var(--row-sm-new-line-spacing-invert));
  margin-bottom: var(--row-sm-new-line-spacing-invert);
}

.row--sm-new-line-spacing > [class*='col'] {
  margin-top: var(--row-sm-new-line-spacing);
}

// from default
.row--md-new-line-spacing {
  transform: translateY(var(--row-md-new-line-spacing-invert));
  margin-bottom: var(--row-md-new-line-spacing-invert);
}

.row--md-new-line-spacing > [class*='col'] {
  margin-top: var(--row-md-new-line-spacing);
}

// from articles and features
.row--lg-new-line-spacing {
  transform: translateY(var(--row-lg-new-line-spacing-invert));
  margin-bottom: var(--row-lg-new-line-spacing-invert);
}

.row--lg-new-line-spacing > [class*='col'] {
  margin-top: var(--row-lg-new-line-spacing);
}

// from contacts
.row--xl-new-line-spacing {
  transform: translateY(var(--row-xl-new-line-spacing-invert));
  margin-bottom: var(--row-xl-new-line-spacing-invert);
}

.row--xl-new-line-spacing > [class*='col'] {
  margin-top: var(--row-xl-new-line-spacing);
}

/* Elements vertical indent
------------------------------------ */

* + h4 {
  margin-top: 40px; // from demo in feature section
}

* + p {
  // from footer 1
  margin-top: 16px;
}

* + h2,
* + .heading-2,
h2 + p,
.heading-2 + p {
  margin-top: 14px; // from products
}

* + .heading-5,
.heading-5 + * {
  // from CTA Centered & Large Feature
  margin-top: 20px;
}

* + .link {
  margin-top: 16px;
}

* + .shop-item {
  margin-top: 40px;
}

* + .article-post {
  margin-top: 40px;
}

* + .icon {
  margin-top: 15px;
}

* + .group {
  margin-top: 20px;
}

* + ws-contact-form-container {
  margin-top: 16px;
}

* + .row {
  margin-top: 30px;
}

* + .service-item {
  margin-top: 40px;
}

* + .map {
  margin-top: 50px;
}

* + .container {
  // for demo gmap
  margin-top: 50px;
}

* + .table-responsive {
  margin-top: 32px;
}

* + .contact-info {
  margin-top: 32px;
}

* + .testimonial {
  margin-top: 40px;
}

* + .pricing-item {
  margin-top: 30px;
}

* + .feature-item {
  margin-top: 30px;
}

* + .group--partners-4-column,
* + .group--partners-5-column,
* + .group--partners-6-column {
  margin-top: 50px;
}

.button + *,
* + .button {
  margin-top: 20px; // from products
}

* + .small {
  margin-top: 10px; // from products
}

p + .button {
  margin-top: 24px;
}

.table-responsive + .button,
.row + .button {
  margin-top: 35px;
}

.form__group + .button {
  margin-top: 24px;
}

.form__group + .form__group {
  margin-top: 16px;
}

@media (min-width: $screen-width-lg) {
  * + h2,
  * + .heading-2,
  h2 + p {
    margin-top: 33px; // from products
  }
  * + .heading-5,
  .heading-5 + * {
    // from CTA Centered & Large Feature
    margin-top: 25px;
  }
  p + .button {
    margin-top: 32px;
  }
  .button + *,
  * + .button {
    margin-top: 39px; // from products
  }
  * + .small {
    margin-top: 32px; // from products
  }
  * + .row {
    margin-top: 40px;
  }
  .table-responsive + .button,
  .row + .button {
    margin-top: 47px;
  }
  * + .group--partners-4-column,
  * + .group--partners-5-column,
  * + .group--partners-6-column {
    margin-top: 64px;
  }
}
