/* Testimonial Component
------------------------------ */

// variables block
:root {
  --testimonial-font-family: var(--base-font-family);
  --testimonial-indent-bettween-items: 16px;

  --testimonial-cite-font-style: normal;
  --testimonial-cite-font-size: var(--base-font-size);
  --testimonial-cite-line-height: var(--base-line-height);
  --testimonial-cite-font-weight: 600;

  --testimonial-position-font-style: italic;
  --testimonial-position-font-size: var(--base-font-size);
  --testimonial-position-line-height: var(--base-line-height);

  --testimonial-image-border-radius: 50%;
  --testimonial-image-width: 48px;
  --testimonial-image-height: 48px;

  --testimonial-quote-font-size: var(--base-font-size);
  --testimonial-quote-line-height: var(--base-line-height);

  --testimonial-md-indent-bettween-items: 12px;
  --testimonial-md-body-min-width: 170px;
  --testimonial-md-image-width: 104px;
  --testimonial-md-image-height: 104px;
  --testimonial-md-column-gap: 30px;

  --testimonial-lg-indent-bettween-items: 24px;
  --testimonial-lg-quote-font-size: 20px;
  --testimonial-lg-quote-line-height: 28px;
  --testimonial-lg-quote-font-weight: 300;
  --testimonial-lg-image-width: 64px;
  --testimonial-lg-image-height: 64px;
}

.testimonial {
  text-align: left;
  font-family: var(--base-font-family);
  display: flex;
  flex-direction: column-reverse;
  &__quote-block {
    font-size: 0;
    line-height: 1;
  }
  &__quote-block > * + * {
    margin-top: var(--testimonial-indent-bettween-items);
  }
  &__cite + &__position {
    margin-top: 0;
  }
  &__position {
    font-style: var(--testimonial-position-font-style);
    font-size: var(--testimonial-position-font-size);
    line-height: var(--testimonial-position-line-height);
  }
  &__cite {
    font-style: var(--testimonial-cite-font-style);
    font-weight: var(--testimonial-cite-font-weight);
    font-size: var(--testimonial-cite-font-size);
    line-height: var(--testimonial-cite-line-height);
  }
  &__img {
    border-radius: var(--testimonial-image-border-radius);
    width: var(--testimonial-image-width);
    height: var(--testimonial-image-height);
  }
  &__quote {
    display: block;
    font-size: var(--testimonial-quote-font-size);
    line-height: var(--testimonial-quote-line-height);
  }
  &__body {
    margin-top: var(--testimonial-indent-bettween-items);
    display: flex;
  }
  &__cite-block {
    margin-left: var(--testimonial-indent-bettween-items);
  }

  &--default {
    .testimonial {
      &__quote {
        color: var(--color-grey-800);
      }
      &__cite {
        color: var(--color-grey-800);
      }
      &__position {
        color: var(--color-grey-500);
      }
    }
  }
}

@media (min-width: $screen-width-lg) {
  .testimonial {
    &--md {
      flex-direction: row;
      .testimonial {
        &__body > * + *,
        &__quote-block > * + * {
          margin-top: var(--testimonial-md-indent-bettween-items);
        }
        &__body {
          flex-direction: column;
          align-items: center;
          margin-top: 0;
          min-width: var(--testimonial-md-body-min-width);
        }
        &__img {
          width: var(--testimonial-md-image-width);
          height: var(--testimonial-md-image-height);
        }
        &__cite-block {
          margin-left: 0;
          text-align: center;
        }
        &__quote-block {
          margin-left: var(--testimonial-md-column-gap);
        }
      }
    }
    &--lg {
      text-align: center;
      .testimonial {
        &__body {
          margin-top: var(--testimonial-lg-indent-bettween-items);
          flex-direction: column;
          align-items: center;
          > * + * {
            margin-top: var(--testimonial-indent-bettween-items);
          }
        }
        &__cite-block {
          margin-left: 0;
        }
        &__quote {
          font-weight: var(--testimonial-lg-quote-font-weight);
          font-size: var(--testimonial-lg-quote-font-size);
          line-height: var(--testimonial-lg-quote-line-height);
        }
        &__img {
          width: var(--testimonial-lg-image-width);
          height: var(--testimonial-lg-image-height);
        }
      }
    }
  }
}
