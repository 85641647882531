/* Media Elements
------------------------------ */

// variables block
:root {
  --media-element-height: 56.27%;
}

.media-element {
  padding-bottom: var(--media-element-height);
}
