.context-grey-800 {
  color: var(--color-white);

  @include headings {
    color: var(--color-white);
  }
  .small {
    color: var(--color-grey-300);
  }

  @include headings('.small' p) {
    a {
      color: var(--color-accent-400);
      &:hover,
      &:active {
        color: var(--color-white);
      }
    }
  }

  a {
    @include headings {
      color: var(--color-accent-400);
    }

    &:hover,
    &:active {
      @include headings {
        color: var(--color-white);
      }
    }
  }

  a > .icon-wrapper {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-500);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-500);
      }
    }
    &:hover > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-white);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-white);
      }
    }
  }

  .button--default {
    background-color: var(--color-white);
    color: var(--color-accent-600);
    border-color: var(--color-white);
    &:hover {
      background-color: transparent;
      color: var(--color-white);
      border-color: var(--color-white);
    }
    &:active {
      background-color: var(--color-accent-700);
    }
  }

  .icon-wrapper--default {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-accent-500);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-accent-500);
      }
    }
  }

  .feature-item {
    &--default {
      color: var(--color-grey-400);
      .feature-item {
        &__title {
          color: var(--color-white);
        }
      }
    }
    &--plates {
      border: 1px solid var(--color-grey-600);
      background: transparent;
    }
  }

  .link {
    color: var(--color-accent-400);
    &:hover,
    &:active {
      color: var(--color-white);
    }
    &--scroll-action {
      svg {
        fill: var(--color-white);
      }
    }
  }

  .table--default {
    color: var(--color-grey-400);
    tr {
      border-bottom-color: var(--color-grey-600);
    }
    thead tr {
      border-bottom-color: var(--color-white);
    }
    tbody {
      td:first-child,
      td:last-child {
        color: var(--color-white);
        font-weight: 600;
      }
      tr:hover {
        background: var(--color-grey-700);
      }
    }
  }

  .pricing-item--default {
    color: var(--color-grey-400);
    border-color: var(--color-grey-600);
    .pricing-item {
      &__title,
      &__price {
        color: var(--color-white);
      }
    }
  }

  .team-member-item--default {
    .team-member-item {
      &__name,
      &__description {
        color: var(--color-white);
      }
      &__position {
        color: var(--color-grey-400);
      }
    }
  }

  .article-post {
    &--default {
      color: var(--color-white);
      .article-post {
        &__author {
          color: var(--color-grey-400);
        }
      }
    }
    &__link {
      color: var(--color-accent-400);
      &:hover {
        color: var(--color-white);
      }
    }
    &--alternate {
      color: var(--color-grey-400);

      .article-post {
        &__body {
          background: transparent;
          border-color: var(--color-grey-600);
        }
      }
    }
  }

  .testimonial--default {
    .testimonial {
      &__quote,
      &__cite {
        color: var(--color-white);
      }
      &__position {
        color: var(--color-grey-400);
      }
    }
  }
  .contact-info {
    &--default {
      color: var(--color-white);
    }
    &__body a {
      color: var(--color-accent-400);
      &:hover,
      &:active {
        color: var(--color-white);
      }
    }
  }

  .service-item__description {
    color: var(--color-grey-400);
  }
}

.context-accent-600 {
  color: var(--color-white);

  @include headings {
    color: var(--color-white);
  }
  .small {
    color: var(--color-grey-300);
  }

  @include headings('.small' p) {
    a {
      text-decoration: underline;
      color: var(--color-white);
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  a > .icon-wrapper {
    > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-white);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-white);
      }
    }
    &:hover > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-800);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-800);
      }
    }
  }

  .button--default {
    background-color: var(--color-white);
    color: var(--color-accent-600);
    border-color: var(--color-white);
    &:hover {
      background-color: transparent;
      color: var(--color-white);
      border-color: var(--color-white);
    }
    &:active {
      background-color: var(--color-accent-700);
    }
  }

  .icon-wrapper--default {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-white);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-white);
      }
    }
  }

  .link {
    text-decoration: underline;
    color: var(--color-white);
    &:hover,
    &:active {
      text-decoration: none;
      color: var(--color-white);
    }
  }

  .feature-item {
    &--default {
      color: var(--color-grey-300);
      .feature-item {
        &__title {
          color: var(--color-white);
        }
      }
    }

    &--plates {
      border: 1px solid var(--color-accent-700);
      background: var(--color-accent-700);
    }
  }

  .contact-info {
    &--default {
      color: var(--color-white);
    }
    &__body a {
      text-decoration: underline;
      color: var(--color-white);
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }
}

.context-grey-100 {
  .table--default {
    tbody {
      tr:hover {
        background: var(--color-white);
      }
    }
  }
  .feature-item--plates {
    background: var(--color-white);
  }
  .article-post--alternate {
    .article-post {
      &__body {
        background: var(--color-white);
      }
    }
  }
}
