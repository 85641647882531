/* Team Member Component
------------------------------ */

// variables block
:root {
  --team-member-item-image-border-radius: 4px;
  --team-member-item-md-image-height: 170px;
  --team-member-item-lg-image-height: 170px;

  --team-member-item-md-name-font-size: var(--heading-4-size);
  --team-member-item-md-name-line-height: var(--heading-4-line-height);

  --team-member-item-lg-name-font-size: var(--heading-2-size);
  --team-member-item-lg-name-line-height: var(--heading-2-line-height);
  --team-member-item-lg-description-font-size: var(--heading-5-size);
  --team-member-item-lg-description-line-height: var(--heading-5-line-height);
  --team-member-item-lg-description-font-weight: 300;

  --team-member-item-fullwidth-content-horizontal-padding: 30px;
}

.team-member-item {
  &__body {
    margin-top: 0;
  }
  &__image {
    border-radius: var(--team-member-item-image-border-radius);
    &--rounded {
      border-radius: 50%;
    }
  }
  &__description--with-padding {
    padding-left: 12px;
    padding-right: 12px;
  }

  &--md {
    .team-member-item {
      &__name {
        font-size: var(--team-member-item-md-name-font-size);
        line-height: var(--team-member-item-md-name-line-height);
      }
      &__image {
        height: 170px;
      }
      > * + *,
      &__body > * + * {
        margin-top: 12px;
      }
      &__image + * {
        margin-top: 24px;
      }
    }
    * + .group--lg,
    .group--lg + * {
      margin-top: 16px;
    }
  }

  &--lg {
    > * + * {
      margin-top: 24px;
    }
    .team-member-item {
      &__body {
        > * + * {
          margin-top: 10px;
        }
        > * + .group--lg {
          margin-top: 24px;
        }
      }
      &__body {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
      }
      &__name {
        font-size: var(--team-member-item-lg-name-font-size);
        line-height: var(--team-member-item-lg-name-line-height);
      }
      &__description {
        font-size: var(--team-member-item-lg-description-font-size);
        line-height: var(--team-member-item-lg-description-line-height);
        font-weight: var(--team-member-item-lg-description-font-weight);
      }
    }
  }

  &--default {
    .team-member-item {
      &__name {
        color: var(--color-grey-800);
      }
      &__description {
        color: var(--color-grey-800);
      }
    }
  }
}

@media (min-width: $screen-width-lg) {
  .team-member-item--md {
    * + .group--lg,
    .group--lg + * {
      margin-top: 24px;
    }
  }
  .team-member-item--lg {
    > * + * {
      margin-top: 24px;
    }
    .team-member-item {
      &__body > * + * {
        margin-top: 24px;
      }
    }
  }
  .team-member-item {
    &--horizontal {
      text-align: left;
      display: flex;
      align-items: flex-start;
      .team-member-item__body {
        margin-top: 0;
        margin-left: 30px;
      }
    }
    &--image-aside-left .team-member-item__body {
      padding-left: var(--team-member-item-fullwidth-content-horizontal-padding);
    }
    &--image-aside-right {
      flex-direction: row-reverse;
      .team-member-item__body {
        margin-left: 0;
        padding-right: var(--team-member-item-fullwidth-content-horizontal-padding);
      }
    }
  }
}

@media (min-width: $screen-width-xl) {
  :root {
    --team-member-item-fullwidth-content-horizontal-padding: 100px;
  }
}
