/* WS nav
------------------------------ */

// variables block
:root {
  --ws-nav-height: 48px;
  --ws-nav-layout-md-heihgt: 80px;

  --ws-nav-white-background-color: var(--color-white);
  --ws-nav-white-content-color: var(--color-grey-800);
  --ws-nav-white-hover-color: var(--color-accent-600);

  --ws-nav-transparent-background-color: var(--color-grey-800);
  --ws-nav-transparent-content-color: var(--color-white);
  --ws-nav-transparent-hover-color: var(--color-accent-400);
}

.ws-nav {
  display: block;
  &__inner {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: var(--ws-nav-height);
    z-index: 2;
    .container {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &--layout-md {
    .ws-nav__inner {
      height: var(--ws-nav-layout-md-heihgt);
      .container {
        justify-content: space-between;
      }
    }
  }
  &--white {
    height: var(--ws-nav-height);
    background: var(--ws-nav-white-background-color);
    .ws-nav__inner {
      background: white;
    }
    &.ws-nav--layout-md {
      height: var(--ws-nav-layout-md-heihgt);
    }
  }
  &--transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 2;
  }

  &[is-stuck]:not([data-ws-id]) {
    // should be changed once we will add some class to the body
    .ws-nav__inner {
      position: fixed;
    }
    &.ws-nav--transparent {
      .ws-nav__inner {
        background: var(--ws-nav-transparent-background-color);
      }
    }
  }
}

// nav additions
.ws-nav__phone {
  color: var(--ws-nav-white-content-color);
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 24px;
  a {
    display: block;
  }
}

.ws-nav--layout-md {
  .ws-nav__phone {
    margin-top: 0;
    margin-left: 35px;
  }
  @media (min-width: $screen-width-lg) {
    .ws-nav__phone {
      margin-left: 45px;
    }
  }
}

.ws-nav--transparent {
  .ws-nav__phone {
    color: var(--ws-nav-transparent-content-color);
  }
  a .icon-wrapper {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--ws-nav-transparent-content-color);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--ws-nav-transparent-content-color);
      }
    }
    &:hover {
      & > svg [data-name] {
        // for stroke part
        &[stroke] {
          stroke: var(--ws-nav-transparent-hover-color);
        }
        // for fill part
        &[fill]:not([fill='none']) {
          fill: var(--ws-nav-transparent-hover-color);
        }
      }
    }
  }
}

.ws-nav__socials {
  line-height: 1;
  font-size: 0;
  margin-left: auto;
}

@import 'ws-nav-collapse';
@import 'ws-nav-menu';
@import 'ws-nav-toggle';
