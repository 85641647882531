/* Contact Info
------------------------------ */

// variables block
:root {
  --contact-info-xs-indent-between-items: 8px;
  --contact-info-sm-indent-between-items: 16px;
  --contact-info-md-indent-between-items: 18px;
  --contact-info-lg-indent-between-items: 20px;

  --contact-info-sm-font-size: var(--base-font-size);
  --contact-info-sm-line-height: var(--base-line-height);
  --contact-info-md-font-size: var(--heading-5-size);
  --contact-info-md-line-height: var(--heading-5-line-height);
  --contact-info-lg-font-size: var(--heading-4-size);
  --contact-info-lg-line-height: var(--heading-4-line-height);
}

.contact-info {
  list-style-type: none;
  text-align: center;

  &__body {
    * + * {
      margin-top: 0;
    }

    a {
      display: block;
      transition: var(--transition-material-standard);
      color: var(--color-accent-600);
      &:hover,
      &:active {
        color: var(--color-accent-700);
      }
    }

    address {
      font-style: normal;
    }
  }

  &__body-link span {
    display: inherit;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-wrapper {
    margin-left: auto;
    margin-right: auto;
    svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-400);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-400);
      }
    }
  }

  a .icon-wrapper {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-500);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-500);
      }
    }
    &:hover > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-grey-800);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-grey-800);
      }
    }
  }

  li > * + * {
    margin-top: var(--contact-info-xs-indent-between-items);
  }

  &--vertical {
    li {
      display: flex;
    }
    .icon-wrapper {
      flex-shrink: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .contact-info__body {
      margin-left: var(--contact-info-xs-indent-between-items);
      margin-top: -3px;
      text-align: left;
    }
  }

  &--sm {
    font-size: var(--contact-info-sm-font-size);
    line-height: var(--contact-info-sm-line-height);
    &.contact-info--vertical {
      li + li {
        margin-top: var(--contact-info-sm-indent-between-items);
      }
    }
  }

  &--md {
    font-size: var(--contact-info-md-font-size);
    line-height: var(--contact-info-md-line-height);
    &.contact-info--vertical {
      li + li {
        margin-top: var(--contact-info-md-indent-between-items);
      }
    }
  }

  &--lg {
    font-size: var(--contact-info-lg-font-size);
    line-height: var(--contact-info-lg-line-height);
    font-weight: 300;
    &.contact-info--vertical {
      li + li {
        margin-top: var(--contact-info-lg-indent-between-items);
      }
    }
  }

  &--default {
    color: var(--color-grey-800);
  }
}

@media (min-width: $screen-width-lg) {
  :root {
    --contact-info-xs-indent-between-items: 16px;
    --contact-info-sm-indent-between-items: 24px;
    --contact-info-md-indent-between-items: 28px;
    --contact-info-lg-indent-between-items: 32px;
  }
}
