/* Shop Items
------------------------------ */

// variables block
:root {
  --shop-item-image-border-radius: 4px;
  --shop-item-description-width: 250px;
  --shop-item-price-color: var(--color-grey-800);
  --shop-item-price-font-size: 20px;
  --shop-item-price-line-height: 24px;
}

.shop-item {
  &__body > * + *,
  > * + * {
    margin-top: 12px;
  }
  &__image + * {
    margin-top: 20px;
  }
  &__body {
    margin-top: 0;
  }
  &__image {
    border-radius: var(--shop-item-image-border-radius);
  }
  &__title {
    display: inline-block;
    font-weight: 400;

    @include headings {
      font-weight: 400;
    }
  }
  &__price {
    font-size: var(--shop-item-price-font-size);
    line-height: var(--shop-item-price-line-height);
    color: var(--shop-item-price-color);
  }
  &--2-columns {
    & > * {
      padding-left: 0;
      padding-right: 0;
    }
    .shop-item__body > * {
      max-width: 270px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--4-columns {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
  &--3-columns {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-width-sm) {
  .shop-item--2-columns > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: $screen-width-md) {
  .shop-item__description {
    max-width: var(--shop-item-description-width);
  }
}
