/* WS nav collapse
------------------------------ */
// variables block
:root {
  --ws-nav-sidebar-width: 270px;
  --ws-nav-sidebar-padding-top: var(--ws-nav-height);
  --ws-nav-sidebar-padding-bottom: 30px;
  --ws-nav-sidebar-padding-horizontal: 16px;

  --ws-nav-sidebar-overlay-color: var(--color-black);
  --ws-nav-sidebar-overlay-opacity: 0.4;

  --ws-nav-md-layout-submenu-offset-top: 50px;
  --ws-nav-md-layout-submenu-width: 215px;
  --ws-nav-md-layout-submenu-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  --ws-nav-md-layout-submenu-border-radius: var(--base-border-radius);
}

// main menu collapse
.ws-nav__menu {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: var(--ws-nav-sidebar-width);
  height: 100vh;
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
  padding: var(--ws-nav-sidebar-padding-top) var(--ws-nav-sidebar-padding-horizontal)
    var(--ws-nav-sidebar-padding-bottom);
  background-color: var(--ws-nav-white-background-color);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 5000px;
    background-color: var(--ws-nav-sidebar-overlay-color);
    opacity: var(--ws-nav-sidebar-overlay-opacity);
    transition: none;
  }
  &[visible] {
    transition: var(--transition-material-standard);
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

// submenu collapses
.ws-nav__submenu {
  display: none;
  &[visible] {
    display: block;
  }
}

.ws-nav--layout-md {
  .ws-nav__menu {
    position: static;
    height: auto;
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;
    background-color: transparent;
    &:before {
      display: none;
    }
    &[visible] {
      transition: none;
    }
  }
  .ws-nav__submenu {
    visibility: hidden;
    opacity: 0;
    display: block;
    position: absolute;
    left: 0;
    top: var(--ws-nav-md-layout-submenu-offset-top);
    width: var(--ws-nav-md-layout-submenu-width);
    background: var(--ws-nav-white-background-color);
    border-radius: var(--ws-nav-md-layout-submenu-border-radius);
    box-shadow: var(--ws-nav-md-layout-submenu-shadow);
    margin-left: calc(var(--ws-nav-menu-md-layout-item-padding-horizontal) * -1);
    &[visible] {
      transition: var(--transition-material-standard);
      opacity: 1;
      visibility: visible;
    }
    .ws-nav__submenu {
      top: 0;
      left: 100%;
      margin-top: -8px;
      margin-left: 8px;
    }
  }
}

.ws-nav--transparent {
  .ws-nav__menu {
    background-color: var(--ws-nav-transparent-background-color);
  }
  &.ws-nav--layout-md {
    .ws-nav__menu {
      background-color: transparent;
    }
    .ws-nav__submenu {
      background: var(--ws-nav-transparent-background-color);
    }
  }
}

.ws-nav__menu-list-item > .ws-nav__submenu[out-of-screen] {
  left: auto;
  right: 0;
}

.ws-nav__submenu-list-item > .ws-nav__submenu[out-of-screen] {
  left: auto;
  right: 100%;
  margin-right: 8px;
  transition: none;
}
