// stylelint-disable declaration-no-important

//
// Utilities for common `text-align` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left {
      text-align: left;
    }
    .text#{$infix}-right {
      text-align: right;
    }
    .text#{$infix}-center {
      text-align: center;
    }
  }
}
