/* Article posts
------------------------------ */

/**
 * We must use the neighboring selectors for indenting in our template.
 * This is due to the fact that inside of our block by means of the builder can
 * be inserted any other piece of code and the elements inside can change their positions.
 * When a user inserts some block inside our article-post__body it must have some top and bottom offsets
 *
 * In order to be able to easily override this style, a selector is used with the minimum possible specificity.
 * For example:
 * .article-post__body > *:not(:first-child) has more specify then .article-post__body > * + *
*/

// variables block
:root {
  --article-post-vertical-indent-between-items: 16px;
  --article-post-author-vertical-indent: 8px;
  --article-post-border-radius: 4px;

  --article-post-alt-body-padding-top: 20px;
  --article-post-alt-body-padding-horizontal: 24px;
  --article-post-alt-body-padding-bottom: 15px;
}

/**
* All elements inside article should be linked to some URL, its a common UX pattern for blogging, eg. Medium
* We have a problem with using a few links with the same URL inside one Article because of using builder.
* A user will need to replace the same link in several places within the same article, for example on the title, picture and other.
* To solve this was a temporary solution to wrap the entire article in a link
* Also, in this case, was redefined the behavior of the entire article in the context of the link.
*/

.article-post {
  &__wrapper {
    display: block;
    > * + * {
      margin-top: var(--article-post-vertical-indent-between-items);
    }
  }

  &__body > * + * {
    margin-top: var(--article-post-vertical-indent-between-items);
  }
  &__link {
    // component that simulate link behavior, created due to second comment (semantically we can not use link inside other link)
    display: inline-block;
    transition: var(--transition-material-standard);
    color: var(--color-accent-600);
    &:hover {
      color: var(--color-accent-700);
    }
  }
  &__author + *,
  * + &__author {
    margin-top: var(--article-post-author-vertical-indent);
  }
  &__image {
    pointer-events: auto;
    border-radius: var(--article-post-border-radius);
  }

  &--default {
    color: var(--color-grey-800);
    .article-post {
      &__author {
        color: var(--color-grey-500);
      }
    }
  }

  &--4-columns,
  &--3-columns {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }

  &--alternate {
    color: var(--color-grey-600);
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;

    .article-post {
      &__body {
        margin-top: 0;
        padding: var(--article-post-alt-body-padding-top)
          var(--article-post-alt-body-padding-horizontal)
          var(--article-post-alt-body-padding-bottom);
        border: 1px solid;
        border-top: 0;
        border-color: var(--color-grey-300);
        border-bottom-right-radius: var(--article-post-border-radius);
        border-bottom-left-radius: var(--article-post-border-radius);
        background: var(--color-grey-100);
        h5,
        .heading-5 {
          font-weight: 600;
        }
      }
      &__image {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  &--single {
    * + .article-post__image,
    .article-post__image + * {
      margin-top: 20px;
    }
    h5 + *,
    h2 + *,
    .heading-5 + *,
    .heading-2 + * {
      margin-top: 15px;
    }
    .article-post__body {
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
  }
}

@media (min-width: $screen-width-lg) {
  :root {
    --article-post-vertical-indent-between-items: 16px;
    --article-post-alt-body-padding-bottom: 24px;
  }
}

@media (min-width: $screen-width-md) {
  .article-post--single {
    .article-post__body > p + p {
      margin-top: 15px;
    }
    * + .article-post__image {
      margin-top: 40px;
    }
    .article-post__image + * {
      margin-top: 48px;
    }
    h2 + *,
    .heading-2 + * {
      margin-top: 24px;
    }

    h5 + *,
    .heading-5 + * {
      margin-top: 32px;
    }
    .article-post__body {
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
  }
}
