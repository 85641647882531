/* Tables
------------------------------ */

// variables block
:root {
  --table-font-family: var(--base-font-family);
  --table-vertical-align: middle;
  --table-text-align: left;
  --table-right-column-text-align: right;
  --table-font-weight: 400;

  --table-md-text-font-size: 16px;
  --table-md-text-line-height: 26px;
  --table-md-cell-padding-vertical: 16px;
  --table-md-cell-padding-horizontal: 24px;
}

.table {
  width: 100%;
  table-layout: auto;
  font-family: var(--table-font-family);
  text-align: var(--table-text-align);
  &-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    max-width: 92vw;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  td,
  th {
    vertical-align: var(--table-vertical-align);
    font-weight: var(--table-font-weight);
    &:first-child {
      width: 17%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:nth-child(2) {
      min-width: 390px;
    }
    &:last-child {
      text-align: var(--table-right-column-text-align);
      width: 17%;
    }
  }
  tr {
    transition: var(--transition-material-standard);
    border-bottom: 1px solid;
  }

  &--default {
    color: var(--color-grey-600);
    tr {
      border-bottom-color: var(--color-grey-300);
    }
    thead tr {
      border-bottom-color: var(--color-grey-800);
    }
    tbody {
      td:first-child,
      td:last-child {
        color: var(--color-grey-800);
        font-weight: 600;
      }
      tr:hover {
        background: var(--color-grey-100);
      }
    }
  }

  &--md {
    font-size: var(--table-md-text-font-size);
    line-height: var(--table-md-text-line-height);
    td,
    th {
      padding: var(--table-md-cell-padding-vertical) var(--table-md-cell-padding-horizontal);
    }
  }
}
