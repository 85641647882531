/* Pricing item
------------------------------ */

// variables block
:root {
  --pricing-border-radius: var(--base-border-radius);

  --pricing-md-text-font-size: var(--base-font-size);
  --pricing-md-text-line-height: var(--base-line-height);
  --pricing-md-padding-vertical: 30px;
  --pricing-md-padding-horizontal: 32px;

  --pricing-md-title-font-size: var(--heading-5-size);
  --pricing-md-title-line-height: var(--heading-5-line-height);
  --pricing-md-title-font-weight: 600;

  --pricing-md-price-font-size: 42px;
  --pricing-md-price-line-height: 48px;
  --pricing-md-price-font-weight: 300;
}

.pricing-item {
  width: 100%;
  text-align: center;
  border: 1px solid;

  * + &__title,
  * + &__price,
  * + &__description {
    margin-top: 32px;
  }

  * + &__description-item {
    margin-top: 16px;
  }

  &--default {
    color: var(--color-grey-600);
    border-radius: var(--pricing-border-radius);
    border-color: var(--color-grey-300);
    .pricing-item {
      &__title {
        color: var(--color-grey-800);
      }
      &__price {
        color: var(--color-grey-800);
      }
    }
  }

  &--md {
    padding: var(--pricing-md-padding-vertical) var(--pricing-md-padding-horizontal);
    font-size: var(--pricing-md-text-font-size);
    line-height: var(--pricing-md-text-line-height);

    .pricing-item {
      &__title {
        font-size: var(--pricing-md-title-font-size);
        line-height: var(--pricing-md-title-line-height);
        font-weight: var(--pricing-md-title-font-weight);
      }
      &__price {
        font-size: var(--pricing-md-price-font-size);
        line-height: var(--pricing-md-price-line-height);
        font-weight: var(--pricing-md-price-font-weight);
      }
    }
  }
}
