/* Blocks
------------------------------ */

/**
* This file contain all styles for specific blocks.
* All blocks are located on ws/internal/blocks.
* All blocks has own specific name, the class of block should be the same.
* You should add styles to this file only in situation then you really need it.
* For example, in all your template .section--xs-padding was 24px, but your block has specific padding with 18px.
* Another one example, by default all icons inside link (tag <a>) hovered with red color, but in this one block hover color is blue.
*/

.block-cta-thin-white,
.block-cta-thin-grey,
.block-cta-thin-dark,
.block-cta-thin-primary {
  h3 {
    font-size: var(--heading-2-size);
    line-height: var(--heading-2-line-height);
  }
  @media (min-width: $screen-width-sm) {
    h3 {
      font-size: var(--heading-3-size);
      line-height: var(--heading-3-line-height);
    }
  }
}

.block-team-2-col-white,
.block-team-2-col-grey,
.block-team-2-col-dark,
.block-team-3-col-grey,
.block-team-3-col-dark,
.block-team-3-col-white,
.block-team-4-col-white,
.block-team-4-col-grey,
.block-team-4-col-dark {
  @media (min-width: $screen-width-sm) {
    * + .row {
      margin-top: 40px;
    }
  }
  @media (min-width: $screen-width-lg) {
    * + .row {
      margin-top: 60px;
    }
  }
}

.block-first-screen-centered-dark,
.block-first-screen-centered-grey,
.block-first-screen-centered-white,
.block-first-screen-hero-image-centered,
.block-first-screen-hero-image-left-align,
.block-first-screen-left-align-dark,
.block-first-screen-left-align-grey,
.block-first-screen-left-align-white {
  * + h3 {
    margin-top: 24px;
  }
  h3 + * {
    margin-top: 30px;
  }
  h3,
  .heading-3 {
    color: var(--color-grey-500);
    display: block;
  }
  @media (min-width: $screen-width-md) {
    * + h3 {
      margin-top: 44px;
    }
    h3 + * {
      margin-top: 50px;
    }
  }
}

.block-first-screen-hero-image-centered,
.block-first-screen-hero-image-left-align {
  h3,
  .heading-3 {
    color: var(--color-white);
  }
}

.block-first-screen-centered-dark,
.block-first-screen-centered-grey,
.block-first-screen-centered-white,
.block-first-screen-hero-image-centered,
.block-header-minimal-transparent,
.block-header-phone-transparent,
.block-header-socials-transparent {
  position: relative;
}

.block-header-minimal-transparent,
.block-header-minimal-white,
.block-header-phone-transparent,
.block-header-phone-white,
.block-header-socials-transparent,
.block-header-socials-white {
  &.section--sm-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

[class*='block-footer-'] {
  .logo-wrapper {
    display: inline-block;
  }

  * + .logo-wrapper,
  .logo-wrapper + * {
    margin-top: 22px;
  }
}
