/* Forms
------------------------------ */

// variables block
:root {
  --form-label-font-size: var(--base-font-size);
  --form-label-line-height: var(--base-line-height);
  --form-label-color: var(--color-grey-500);
  --form-label-margin-bottom: 4px;

  --form-text-area-min-height: 50px;
  --form-text-area-height: 120px;
  --form-text-area-max-height: 200px;

  --form-input-font-family: var(--base-font-family);
  --form-input-font-size: var(--base-font-size);
  --form-input-line-height: var(--base-line-height);
  --form-input-padding-vertical: 8px;
  --form-input-padding-horizontal: 16px;
  --form-input-border-width: 1px;
  --form-input-border-radius: 4px;
  --form-input-text-color: var(--color-grey-500);
  --form-input-border-color: var(--color-grey-400);
}

ws-contact-form-container {
  display: block;
}

.form {
  &__group {
    position: relative;
    text-align: left;
  }
  &__label {
    display: inline-block;
    margin-bottom: var(--form-label-margin-bottom);
    font-size: var(--form-label-font-size);
    line-height: var(--form-label-line-height);
    color: var(--form-label-color);
    font-weight: 400;
  }
  &__input {
    background: var(--color-white);
    font-family: var(--form-input-font-family);
    width: 100%;
    font-size: var(--form-input-font-size);
    line-height: var(--form-input-line-height);
    padding: var(--form-input-padding-vertical) var(--form-input-padding-horizontal);
    border-width: var(--form-input-border-width);
    border-radius: var(--form-input-border-radius);
    border-style: solid;
    border-color: var(--form-input-border-color);
    color: var(--form-input-text-color);
    -webkit-appearance: none;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

textarea.form__input {
  height: var(--form-text-area-height);
  min-height: var(--form-text-area-min-height);
  max-height: var(--form-text-area-max-height);
  resize: vertical;
}

// Global response container

.ws-contact-form__output {
  font-size: var(--base-font-size);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-family: var(--base-font-family);
  transform: translateX(105%);

  &.ws-active {
    opacity: 1;
    visibility: visible;
    z-index: 1002;
    transition: var(--transition-material-standard);
    transform: translateX(0);
  }
  .ws-contact-form__output-text {
    padding: 15px 25px;
    color: var(--color-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
    border-radius: var(--base-border-radius);
  }
  &.ws-success {
    .ws-contact-form__output-text {
      background: var(--color-success);
    }
  }
  &.ws-error {
    .ws-contact-form__output-text {
      background: var(--color-danger);
    }
  }
}

.ws-captcha-centered {
  display: flex;
  justify-content: center;
}
