/* Service Items
------------------------------ */

// variables block
:root {
  --service-item-indent-between-items: 10px;

  --service-item-image-border-radius: 4px;

  --service-item-title-font-size: var(--heading-4-size);
  --service-item-title-line-height: var(--heading-4-line-height);
}

.service-item {
  > * + * {
    margin-top: var(--service-item-indent-between-items);
  }
  &__img + * {
    margin-top: 20px;
  }
  &__img {
    border-radius: var(--service-item-image-border-radius);
  }
  &__title {
    display: inline-block;
    font-size: var(--service-item-title-font-size);
    line-height: var(--service-item-title-line-height);
    font-weight: 400;

    @include headings {
      font-weight: 400;
    }
  }
  &--3-columns {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
  &--4-columns {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-width-md) {
  .service-item {
    text-align: left;
  }
}
