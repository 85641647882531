/* Maps
------------------------------ */

// variables block
:root {
  --google-map-md-height: 50%;
  --google-map-full-width-height: 30.625%;
}

.map {
  padding-bottom: var(--google-map-md-height);
}

@media (min-width: $screen-width-lg) {
  :root {
    --google-map-md-height: 110%;
  }
  .map--full-width {
    padding-bottom: var(--google-map-full-width-height);
  }
}
