/* Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 ------------------------------------------------ */

@at-root {
  @-ms-viewport {
    width: device-width;
  } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import 'grid-functions';
@import 'grid-variables';

@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';

@import 'grid';
@import 'utilities/display';
@import 'utilities/flex';
@import 'utilities/text-align';
