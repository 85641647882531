/* WS nav toggle
------------------------------ */
:root {
  --ws-nav-toogle-line-height: 2px;
  --ws-nav-toggle-height: 18px;
  --ws-nav-toggle-width: 24px;
}

// main menu toggle
.ws-nav__toggle {
  display: block;
  cursor: pointer;
  width: var(--ws-nav-toggle-width);
  height: var(--ws-nav-toggle-height);
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &-inner {
    &,
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: var(--ws-nav-toogle-line-height);
      left: 0;
      width: 100%;
      background: var(--ws-nav-white-content-color);
      transition: var(--transition-material-standard);
    }
  }
  &-inner {
    top: 50%;
    margin-top: calc(var(--ws-nav-toogle-line-height) / 2 * -1);
    &:before {
      top: calc(var(--ws-nav-toggle-height) / 2 + var(--ws-nav-toogle-line-height) * -1);
    }
    &:after {
      bottom: calc(var(--ws-nav-toggle-height)/ 2 + var(--ws-nav-toogle-line-height) * -1);
    }
  }

  &[active] {
    .ws-nav__toggle {
      &-inner {
        transform: rotate(45deg);
        &:after {
          top: 0;
          opacity: 0;
        }

        &:before {
          top: 0;
          transform: rotate(90deg);
        }
      }
    }
  }

  &[disconnected] {
    display: none;
  }
  @media (min-width: $screen-width-sm) {
    right: 50px;
  }
}

// submenu toggle
.ws-nav__menu-list-item .ws-nav__toggle {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: none;
  a {
    padding-right: 30px;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 4px 3px;
    border-color: transparent transparent var(--ws-nav-white-content-color) transparent;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }
  &[active] {
    &:after {
      transform: translateY(-50%);
    }
  }
}

.ws-nav--layout-md {
  .ws-nav__menu-list-item {
    .ws-nav__toggle {
      a {
        padding-right: 10px;
      }
      &:after {
        right: -5px;
        margin-top: 1px;
      }
    }
  }
  .ws-nav__submenu-list-item {
    .ws-nav__toggle {
      &:after {
        right: -5px;
        border-width: 3px 0 3px 4px;
        border-color: transparent transparent transparent var(--ws-nav-white-content-color);
        transform: translateY(-50%);
      }
    }
    &:hover {
      > .ws-nav__toggle:after {
        border-color: transparent transparent transparent var(--ws-nav-white-hover-color);
      }
    }
  }
}

.ws-nav--transparent {
  // main menu toggle
  .ws-nav__toggle {
    &-inner {
      &,
      &:before,
      &:after {
        background: var(--ws-nav-transparent-content-color);
      }
    }
  }

  // submenu toggle
  .ws-nav__menu-list-item .ws-nav__toggle {
    &:after {
      border-color: transparent transparent var(--ws-nav-transparent-content-color) transparent;
    }
  }

  &.ws-nav--layout-md {
    .ws-nav__submenu-list-item {
      .ws-nav__toggle {
        &:after {
          border-color: transparent transparent transparent var(--ws-nav-transparent-content-color);
        }
      }
      &:hover {
        > .ws-nav__toggle:after {
          border-color: transparent transparent transparent var(--ws-nav-transparent-hover-color);
        }
      }
    }
  }
}
