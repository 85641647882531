/* Icons
------------------------------ */

// variables block
:root {
  --icon-xs-size: 20px;
  --icon-sm-size: 32px;
  --icon-md-size: 56px;
}

.icon-wrapper {
  display: inline-block;
  & > svg {
    width: 100%;
    height: 100%;
  }
  &--sm {
    width: var(--icon-sm-size);
    height: var(--icon-sm-size);
  }
  &--md {
    width: var(--icon-md-size);
    height: var(--icon-md-size);
  }
  &--xs {
    width: var(--icon-xs-size);
    height: var(--icon-xs-size);
  }
  &--default {
    & > svg [data-name] {
      // for stroke part
      &[stroke] {
        stroke: var(--color-accent-600);
      }
      // for fill part
      &[fill]:not([fill='none']) {
        fill: var(--color-accent-600);
      }
    }
  }
}
