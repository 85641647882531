.background-accent-600 {
  background-color: var(--color-accent-600);
}

.background-grey-800 {
  background-color: var(--color-grey-800);
}

.background-grey-100 {
  background-color: var(--color-grey-100);
}

.background-white {
  background-color: var(--color-white);
}

.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &--1 {
    background-image: url('/img/image-10-1920x800.jpg');
  }
  &--2 {
    background-image: url('/img/image-11-1920x800.jpg');
  }
}
